export const parseLocationSearchString = (str) => {
  str = str || location.search
  let qs = str.length > 0 ? str.substring(1) : ''
  let args = {}
  let items = qs.length > 0 ? qs.split('&') : []
  let item = null, name = null, value = null
  for (let i = 0; i < items.length; i++) {
    item = items[i].split('=');
    name = decodeURIComponent(item[0]);
    value = decodeURIComponent(item[1]);
    if (name.length) {
      args[name] = value;
    }
  }
  return args
}

export const generateLocationSearchString = (obj) => {
  let searchString = ''
  for (let key in obj) {
    if (!searchString) {
      searchString = `?${key}=${obj[key]}`
    } else {
      searchString = `${searchString}&${key}=${obj[key]}`
    }
  }
  return searchString
}

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(window.navigator.userAgent)
}

export const isWeixin = () => {
  return /MicroMessenger/i.test(window.navigator.userAgent)
}

export const isIOS = () => {
  return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
}

export const isAndroid = () => {
  return /(Android)/i.test(navigator.userAgent)
}