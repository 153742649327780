import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    isLogin: false,
    userinfo: {},
  },
  mutations: {
    updateToken(state, data) {
      state.token = data
    },
    updateLogin(state, data) {
      state.isLogin = data
    },
    updateUserinfo(state, data) {
      state.userinfo = data
    },
  },
  actions: {
  },
  modules: {
  }
})
