import axios from 'axios'
const isProd = process.env.NODE_ENV === 'production'
const APIHOST = isProd ? 'https://fc-resource-node-api.krzb.net' : 'http://localhost:20030'

export const request = async ({ method = 'GET', data, url, params, headers = {}, isPrefix = true }) => {
  try {
    const res = await axios({
      method,
      url: isPrefix ? `${APIHOST}${url}` : url,
      data,
      params: {
        ...params
      },
      timeout: 20 * 1000,
      headers: {
        ...headers
      },
    })
    return res.data
  } catch (err) {
    console.log(`request error: ${err}`)
    if (err.response) {
      return err.response.data
    } else {
      return null
    }
  }
}